import { Button, ButtonProps } from '@mui/material';
import StyledImage from '../styledImage';
import { ReactNode } from 'react';

export default function CustomGoogleButton( { children, ...props }: { children: ReactNode } & ButtonProps ) {
	return (
		<Button
			variant='contained'
			color='inverted'
			startIcon={(
				<StyledImage
					sx={{ width: 30, height: 30 }}
					alt='google-logo'
					src='/images/google-icon.png'
				/>
			)}
			size='large'
			{...props}>
			{children}
		</Button>
	);
}
