import { auth } from '@/firebase/client';
import {
	ApplicationVerifier,
	getMultiFactorResolver,
	multiFactor,
	MultiFactorInfo,
	MultiFactorResolver,
	MultiFactorSession,
	PhoneAuthProvider,
	PhoneInfoOptions,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Auth, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

export const loginSignUpErrorMessages: Record<string, string> = {
	'auth/email-already-exists'                    : 'Email is already in use, please log in',
	'auth/email-already-in-use'                    : 'Email is already in use, please log in',
	'auth/invalid-password'                        : 'Invalid password',
	'auth/wrong-password'                          : 'Wrong password, please check your password or use forgot password',
	'auth/invalid-email'                           : 'Invalid email',
	'auth/invalid-credential'                      : 'Invalid credential',
	'auth/account-exists-with-different-credential': 'Account Exists with different credentials',
	'auth/user-not-found'                          : 'User not found, please check your email or password',
	'auth/email-not-verified'                      : 'Email not verified, please check your email for a verification link',
	'auth/invalid-email-verified'                  : 'Invalid email verified',
	'auth/too-many-requests'                       : 'Too many requests, please try again later',
	'auth/requires-recent-login'                   : 'You need to log out and log back in to proceed with this action',
	'auth/invalid-verification-code'               : 'Invalid code, please try again',
	'auth/argument-error'                          : 'Argument error, please try again',
	'auth/operation-not-allowed'                   : 'Operation not allowed, please try again',
	'auth/invalid-phone-number'                    : 'Invalid phone number, please try again',
	'auth/network-request-failed'                  : 'Network request failed, please try again',
};

export function useRecaptcha( componentId: string ): ApplicationVerifier {
	const [ recaptcha, setRecaptcha ] = useState<ApplicationVerifier>();
	const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>( null );
	
	useEffect( () => {
		try {
			if ( !document.getElementById( componentId ) ) {
				console.warn( `Element with ID '${componentId}' not found` );
				return;
			}
			
			const recaptchaVerifier: RecaptchaVerifier = new RecaptchaVerifier( auth, componentId, {
				size    : 'invisible',
				callback: (): void => {
					console.log( 'Recaptcha callback' );
				},
			} );
			
			recaptchaVerifierRef.current = recaptchaVerifier;
			setRecaptcha( recaptchaVerifier );
			
		} catch ( error ) {
			console.warn( `Error creating RecaptchaVerifier, ${error}` );
		}
		
		return () => {
			recaptchaVerifierRef.current?.clear();
			recaptchaVerifierRef.current = null;
		};
	}, [ componentId ] );
	
	return recaptcha;
}

type IsUserEmailVerifiedAndMultiFactorActivated = {
	isUserEmailVerifiedAndMultiFactorActivated: boolean,
	verifiedPhoneNumber?: string
};

export function useIsUserEmailVerifiedAndMultiFactorActivated(): IsUserEmailVerifiedAndMultiFactorActivated {
	const [ firebaseUser ] = useAuthState( auth );
	
	if ( !firebaseUser?.emailVerified ) {
		return {
			isUserEmailVerifiedAndMultiFactorActivated: false,
			verifiedPhoneNumber                       : undefined,
		};
	}
	
	const enrolledFactors = multiFactor( firebaseUser ).enrolledFactors;
	
	const verifiedPhoneNumber = enrolledFactors[ 0 ]
		? ( enrolledFactors[ 0 ] as firebase.auth.PhoneMultiFactorInfo )?.phoneNumber
		: undefined;
	
	return {
		isUserEmailVerifiedAndMultiFactorActivated: enrolledFactors.length > 0,
		verifiedPhoneNumber,
	};
}

export async function verifyPhoneNumber(
	phoneNumber: string,
	recaptchaVerifier: ApplicationVerifier,
): Promise<false | string> {
	const session = await multiFactor( auth.currentUser ).getSession();
	const phoneInfoOptions: PhoneInfoOptions = { phoneNumber, session };
	const phoneAuthProvider = new PhoneAuthProvider( auth );
	try {
		return await phoneAuthProvider.verifyPhoneNumber( phoneInfoOptions, recaptchaVerifier );
	} catch ( e ) {
		throw e;
	}
}

export async function enrollUserInMultiFactorAuthentication(
	verificationCodeId: string,
	verificationCode: string,
): Promise<boolean> {
	const phoneAuthCredential = PhoneAuthProvider.credential( verificationCodeId, verificationCode );
	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion( phoneAuthCredential );
	try {
		await multiFactor( auth.currentUser ).enroll( multiFactorAssertion, 'Personal Phone Number' );
		return true;
	} catch ( e ) {
		throw e;
	}
}

export const validateCode = async (
	verificationCodeId: string,
	verificationCode: string,
	resolver: MultiFactorResolver,
): Promise<boolean> => {
	const phoneAuthCredential = PhoneAuthProvider.credential( verificationCodeId, verificationCode );
	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion( phoneAuthCredential );
	try {
		await resolver.resolveSignIn( multiFactorAssertion );
		return true;
	} catch ( e ) {
		throw e;
	}
};

type MultiFactorErrorHandling = {
	verificationCodeId: string,
	mfaResolver: MultiFactorResolver,
	phoneNbr: string,
	handleMultiFactorError: ( error: Error ) => Promise<void>
};

type MultiFactorPhoneInfoOptions = {
	multiFactorHint: MultiFactorInfo,
	session: MultiFactorSession
};

export function useMultiFactorErrorHandling(): MultiFactorErrorHandling {
	const recaptcha = useRecaptcha( 'recaptcha-container' );
	const [ verificationCodeId, setVerificationCodeId ] = useState<string>( '' );
	const [ mfaResolver, setMfaResolver ] = useState<MultiFactorResolver>( null );
	const [ phoneNbr, setPhoneNbr ] = useState<string>( '' );
	
	const handleMultiFactorError = async ( error ): Promise<void> => {
		const resolver: MultiFactorResolver = getMultiFactorResolver( auth, error );
		const phoneInfoOptions: MultiFactorPhoneInfoOptions = {
			multiFactorHint: resolver.hints[ 0 ],
			session        : resolver.session,
		};
		const phoneAuthProvider: PhoneAuthProvider = new PhoneAuthProvider( auth );
		const verificationId = await phoneAuthProvider.verifyPhoneNumber( phoneInfoOptions, recaptcha );
		setVerificationCodeId( verificationId );
		setMfaResolver( resolver );
		setPhoneNbr( ( phoneInfoOptions.multiFactorHint as firebase.auth.PhoneMultiFactorInfo )?.phoneNumber );
	};
	
	return { verificationCodeId, mfaResolver, phoneNbr, handleMultiFactorError };
}

export const reauthenticateUser = async ( auth: Auth, password: string ): Promise<void> => {
	if ( !auth.currentUser?.email ) {
		throw new Error( 'No user email found' );
	}

	const credential = EmailAuthProvider.credential(
		auth.currentUser.email,
		password,
	);
	await reauthenticateWithCredential( auth.currentUser, credential );
};
