import { Typography } from '@mui/material';
import PageLinkComponent from './page/linkComponent';

export default function Disclaimer() {
	return (
		<Typography variant='caption' color='text.secondary' textAlign='center'>
			By continuing, you are indicating that you accept our{' '}
			<PageLinkComponent
				href='/terms'
				target='_blank'
				sx={{
					'color'       : 'primary.main',
					':not(:hover)': { textDecoration: 'none' },
					'fontWeight'  : 500,
				}}>
				Terms of Service
			</PageLinkComponent>{' '}
			and{' '}
			<PageLinkComponent
				href='/terms'
				target='_blank'
				sx={{
					'color'       : 'primary.main',
					':not(:hover)': { textDecoration: 'none' },
					'fontWeight'  : 500,
				}}>
				Privacy Policy
			</PageLinkComponent>.
		</Typography>
	);
}
