import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

type PasswordCheckerProps = {
	password: string
};

export default function PasswordChecker( { password }: PasswordCheckerProps ) {
	const [ isLongEnough, setIsLongEnough ] = useState( false );
	const [ hasUppercase, setHasUppercase ] = useState( false );
	const [ hasNumber, setHasNumber ] = useState( false );
	const [ hasSpecialChar, setHasSpecialChar ] = useState( false );
	
	useEffect( () => {
		setIsLongEnough( password?.length >= 8 );
		setHasUppercase( /[A-Z]/.test( password ) );
		setHasNumber( /[0-9]/.test( password ) );
		setHasSpecialChar( /[\W]/.test( password ) );
	}, [ password ] );
	
	return (
		<div>
			<Typography sx={{
				color     : isLongEnough ? 'success.main' : 'error.main',
				transition: 'color 0.5s ease-in-out',
			}}>
				Must have 8 characters
			</Typography>
			<Typography sx={{
				color     : hasUppercase ? 'success.main' : 'error.main',
				transition: 'color 0.5s ease-in-out',
			}}>
				One Uppercase
			</Typography>
			<Typography sx={{
				color     : hasNumber ? 'success.main' : 'error.main',
				transition: 'color 0.5s ease-in-out',
			}}>
				One number
			</Typography>
			<Typography sx={{
				color     : hasSpecialChar ? 'success.main' : 'error.main',
				transition: 'color 0.5s ease-in-out',
			}}>
				One special character
			</Typography>
		</div>
	);
}
