import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Box, Collapse, IconButton, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import { Fragment, useState } from 'react';
import type { FormattedTextFieldProps } from '../../formattedTextField';
import FormattedTextField from '../../formattedTextField';
import PasswordChecker from '../passwordErrorMessages';

export default function FormPassword( {
	name,
	disablePasswordChecker,
	...props
}: {
	name: string,
	disablePasswordChecker?: boolean
} & FormattedTextFieldProps ) {
	const [ field, { touched, error } ] = useField( name );
	
	const [ show, setShow ] = useState( false );
	const [ passwordFocused, setPasswordFocused ] = useState( false );
	
	return (
		<Fragment>
			<FormattedTextField
				{...field}
				fullWidth
				type={show ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								edge='end'
								onClick={() => setShow( ( val ) => !val )}>
								{show ? <VisibilityIcon/> : <VisibilityOffIcon/>}
							</IconButton>
						</InputAdornment>
					),
				}}
				error={touched && Boolean( error )}
				helperText={touched && error}
				onFocus={() => !disablePasswordChecker && setPasswordFocused( true )}
				{...props}
			/>
			{!disablePasswordChecker && (
				<Collapse in={Boolean( field.value ) && Boolean( passwordFocused ) && Boolean( touched ) && Boolean( error )}>
					<Box px={1}>
						<PasswordChecker password={field.value}/>
					</Box>
				</Collapse>
			)}
		</Fragment>
	);
}
